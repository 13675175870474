export default {
  fetchOrders ({ commit }, data) {
    commit('SET_ORDERS', data.subscriptions)
    console.log(data)
  },
  addOrder ({ commit }, data) {
    commit('ADD_ITEM', data.subscription)
  },
  updateOrder ({ commit }, data) {
    commit('UPDATE_ORDER', data.subscription)
  },
  deleteOrder ({ commit }, data) {
    commit('REMOVE_ITEM', data.subscription)
  }
}
