import firebase from 'firebase/app'

// Initialize Firebase
let config = {
  apiKey: 'AIzaSyD31y23mgUEJSWGmOk7zvzTYOp2t0fy0do',
  authDomain: 'plain-simple-b852a.firebaseapp.com',
  projectId: 'plain-simple-b852a',
  storageBucket: 'plain-simple-b852a.appspot.com',
  messagingSenderId: '720434816978',
  appId: '1:720434816978:web:e109961ab19a759e63ca0b',
  measurementId: 'G-CBDNESBDQ5'
}
firebase.initializeApp(config)
