export default {
  ADD_ITEM (state, item) {
    state.subscriptions.unshift(item)
  },
  SET_ORDERS (state, subscriptions) {
    state.subscriptions = subscriptions
  },
  UPDATE_ORDER (state, order) {
    const orderIndex = state.subscriptions.findIndex((o) => o.id === order.id)
    Object.assign(state.subscriptions[orderIndex], order)
  },
  REMOVE_ITEM (state, order) {
    const ItemIndex = state.subscriptions.findIndex((o) => o.id === order.id)
    state.subscriptions.splice(ItemIndex, 1)
  }
}
